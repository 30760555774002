import React from 'react';
import './SectionProducts.css';
import PRODUCTS from '../../../models/products';
import COURSES from '../../../models/courses';
import Course from '../../../components/Common/Course';
import Product from '../../../components/Common/Product';
import useFetch from '../../../hooks/useFetch';

function SectionProducts() {
  const { loading, error, data } = useFetch('');

  return (
    <React.Fragment>
      <div className='white'>
        <h2 className='pb-3'>Our Products, Services & Courses</h2>
        <p className='text-justify pb-3'>
          Experience the ultimate in beauty and self-care with our comprehensive
          range of services. From rejuvenating facial treatments and expert hair
          styling to flawless makeup artistry and meticulous manicures and
          pedicures, we cater to all your beauty needs. Enhance your natural
          glow with our gentle waxing, and perfect your look with our eyelash
          and brow services. To ensure the best results, we recommend starting
          with an online consultation, where our professionals can tailor
          treatments to your unique preferences and goals. Discover a
          personalized beauty experience designed just for you.
        </p>
      </div>
      <section id='featured-products' className='section fade-in'>
        <div id='product-carousel'>
          {PRODUCTS.map((product, index) => (
            <Product product={product} key={index} />
          ))}
        </div>
        <div className='row row-cols-auto gx-4'>
          {COURSES.map((course, index) => (
            <Course course={course} key={index} />
          ))}
        </div>
      </section>
    </React.Fragment>
  );
}

export default SectionProducts;
