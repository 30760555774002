import React from 'react';
import './SectionAbout.css';
import SectionExperience from './sections/SectionExperience';
import SectionCertifications from './sections/SectionCertifications';
import useFetch from '../../../hooks/useFetch';

function SectionAbout() {
  const { loading, error, data } = useFetch('');

  return (
    <div className='white'>
      <h2 className='pb-3'>About Dr. Nova</h2>
      <p className='text-justify pb-3'>
        I am dedicated beauty professional with a passion for enhancing natural
        beauty and boosting confidence. With over 5 years in the beauty
        industry, I’ve honed my skills and knowledge to provide top-notch
        services tailored to each unique client’s needs. My approach combines
        creativity, precision, and a deep understanding of the latest beauty
        trends to deliver results that make you look and feel absolute. I
        specialize in facial and body treatments like peels, mesotherapy,
        microneedling and much more. In addition, I am a master of cosmetic
        chemistry, which means I work only with the highest quality products and
        techniques. My passion is to design a one-of-a-kind experience that
        leaves every client feeling refreshed, and confident in their own body
        and skin. Your beauty journey with me goes beyond simple treatments;
        it’s about curating a memorable experience that makes you feel truly
        special. I'm creating a warm, welcoming environment where you can relax
        and trust that you’re in expert's hands. Whether you’re preparing for a
        special event or simply looking to refresh your look, I’m here to help
        you shine!
      </p>

      <SectionExperience />

      <SectionCertifications />
    </div>
  );
}

export default SectionAbout;
