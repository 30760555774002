import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, gql } from '@apollo/client';
import PageTitle from '../../components/Common/PageTitle';
import BlogArticlesList from './sections/BlogArticlesList';
import './Blog.css';
import CategoriesList from './sections/CategoriesList';

const GET_ARTICLES = gql`
  query GetArticles($locale: I18NLocaleCode) {
    categories: articleCategories {
      documentId
      title
    }
    articles(locale: $locale) {
      date
      title
      intro
      categories {
        title
      }
      image {
        url
      }
    }
  }
`;
const OPTIONS = { variables: { locale: 'en' } };

function Blog() {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(GET_ARTICLES, OPTIONS);

  if (loading) return <div>Loading...</div>;

  const apiPrblm = error || loading || !data.articles;

  return (
    <React.Fragment>
      <div className='container my-5'>
        <div className='row mb-3'>
          <div className='col'>
            <PageTitle title={t('blog.title')}></PageTitle>
          </div>
          {/* <hr /> */}
        </div>
        <CategoriesList categories={data.categories} />
        <BlogArticlesList articles={data.articles} />
      </div>
    </React.Fragment>
  );
}

export default Blog;
