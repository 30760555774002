import React from 'react';

function StrapiImage({ url, title, cssClass }) {
  return (
    <img
      src={`https://api.dr-nova.com${url}`}
      alt={title}
      className={cssClass ? cssClass : ''}
    />
  );
}

export default StrapiImage;
