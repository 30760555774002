import React from 'react';
import COURSES from '../../../models/courses';
import Course from '../../../components/Common/Course';
import useFetch from '../../../hooks/useFetch';

function SectionCoursesList() {
  const { loading, error, data } = useFetch('');

  return (
    <div className='row row-cols-auto gx-4'>
      {COURSES.map((course, index) => (
        <Course course={course} key={index} />
      ))}
    </div>
  );
}

export default SectionCoursesList;
