import React from 'react';
import QUESTIONS from '../../models/questions';
import Question from './Question/Question';
import PageTitle from '../../components/Common/PageTitle';
import useFetch from '../../hooks/useFetch';

function FAQ() {
  const { loading, error, data } = useFetch('');

  return (
    <React.Fragment>
      <div className='container my-5'>
        <div className='row mb-3 white'>
          <div className='col'>
            <PageTitle title='Frequently Asked Questions' />
          </div>
        </div>
        <div className='accordion'>
          {QUESTIONS.map((question, index) => (
            <Question index={index} question={question} />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default FAQ;
