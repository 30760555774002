import React from 'react';
import useFetch from '../../hooks/useFetch';
import PageTitle from '../../components/Common/PageTitle';
import { useParams } from 'react-router-dom';

function BlogArticle() {
  // get page id
  const { id } = useParams();

  const { loading, error, data } = useFetch(`url/${id}`);

  return (
    <React.Fragment>
      <PageTitle title={''}></PageTitle>
      <div></div>
    </React.Fragment>
  );
}

export default BlogArticle;
