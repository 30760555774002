import React from 'react';
import TESTIMONIALS from '../../../../models/testimonials';
import useFetch from '../../../../hooks/useFetch';

function TestimonialsCarousel() {
  const { loading, error, data } = useFetch('');

  return (
    <div id='carousel-testi' className='carousel slide pb-4'>
      <div className='carousel-indicators pb-3'>
        {TESTIMONIALS.map((_, i) => (
          <button
            type='button'
            data-bs-target='#carousel-testi'
            data-bs-slide-to={`${i}`}
            className={i === 0 ? 'active' : null}
            aria-current={i === 0 ? 'true' : null}
            aria-label={`Slide ${i}`}
            key={i}></button>
        ))}
      </div>
      <div className='carousel-inner white'>
        {TESTIMONIALS.map((testi, i) => (
          <div
            className={i === 0 ? 'carousel-item active' : 'carousel-item'}
            key={i}>
            <div className='row justify-content-center'>
              <div className='col-lg-3 col-9 d-flex align-items-center justify-content-center'>
                <img
                  src={`/img/testimonials/${testi.image}`}
                  alt={testi.alt}
                  className='w-100 pb-4'
                />
              </div>
              <div className='col-lg-6 col-9'>
                <h5 className='mb-3'>{testi.title}</h5>
                <p className='text-justify'>{testi.text}</p>
                <p className='text-end fst-italic'>{testi.clientName}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <button
        className='carousel-control-prev'
        type='button'
        data-bs-target='#carousel-testi'
        data-bs-slide='prev'>
        <span className='carousel-control-prev-icon' aria-hidden='true'></span>
        <span className='visually-hidden'>Previous</span>
      </button>

      <button
        className='carousel-control-next'
        type='button'
        data-bs-target='#carousel-testi'
        data-bs-slide='next'>
        <span className='carousel-control-next-icon' aria-hidden='true'></span>
        <span className='visually-hidden'>Next</span>
      </button>
    </div>
  );
}

export default TestimonialsCarousel;
