import React from 'react';
import SectionRegals from './sections/SectionRegals';
import './SectionDrNova.css';
import { useQuery, gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import StrapiImage from '../../../components/Common/StrapiImage';

const GET = gql`
  query GetDrNova($locale: I18NLocaleCode) {
    drNova(locale: $locale) {
      title
      slogan
      description
      img {
        url
      }
      bottom
    }
  }
`;
const OPTIONS = { variables: { locale: 'en' } };

function SectionDrNova() {
  const { t } = useTranslation();
  let { loading, error, data } = useQuery(GET, OPTIONS);
  if (loading) return <div>Loading...</div>;

  const apiPrblm = error || loading || !data.drNova;
  if (!apiPrblm) data = data.drNova;

  return (
    <div className='row'>
      <div className='col-sm-12 col-lg-6 align-self-center'>
        <div className='card mb-5 bg-transparent info white'>
          <div className='card-body'>
            <h5 className='card-title mb-3 text-center'>
              {apiPrblm ? t('drNova.title') : data.title}
            </h5>
            <p className='card-text fs-3 mb-3 text-center'>
              {apiPrblm ? t('drNova.slogan') : data.slogan}
            </p>
            <div className='dr-nova card-text'>
              <p className='text-justify mb-4'>
                {apiPrblm ? t('drNova.description') : data.description}
              </p>
              <SectionRegals />
              <p className='text-justify mt-4'>
                {apiPrblm ? t('drNova.bottom') : data.bottom}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-5 text-end align-self-end d-none d-lg-block'>
        {apiPrblm ? (
          <img
            src='/img/kris.png'
            className='img-fluid rounded-start'
            alt='Dr. Nova'
          />
        ) : (
          <StrapiImage
            url={data.img.url}
            title={data.title}
            cssClass='img-fluid rounded-start'
          />
        )}
        {/* <div className='parallax float-right'></div> */}
      </div>
    </div>
  );
}

export default SectionDrNova;
