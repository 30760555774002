import React from 'react';
import REGALS from '../../../../models/regals';
import { useQuery, gql } from '@apollo/client';
import StrapiImage from '../../../../components/Common/StrapiImage';

const GET_REGALS = gql`
  query GetRegals($locale: I18NLocaleCode) {
    regals(locale: $locale) {
      title
      icon {
        url
      }
      cssClass
    }
  }
`;
const OPTIONS = { variables: { locale: 'en' } };

function SectionRegals() {
  let { loading, error, data } = useQuery(GET_REGALS, OPTIONS);

  if (loading) return <div>Loading...</div>;

  const apiPrblm = error || loading || !data.regals;
  data = apiPrblm ? REGALS : data.regals;

  return (
    <div className='regal-list'>
      {data.map((regal, index) => (
        <div className='regal-block' key={index}>
          <div className='regal-img me-3 ms-lg-3'>
            {apiPrblm ? (
              <img
                src={`/img/icons/${regal.icon.url}`}
                alt={regal.title}
                className={regal.cssClass}
              />
            ) : (
              <StrapiImage
                url={regal.icon.url}
                title={regal.title}
                cssClass={regal.cssClass}
              />
            )}
          </div>
          <div className='regal-text'>{regal.title}</div>
        </div>
      ))}

      {/* {data.map((regal) => (
        <div key={regal.id}></div>
      ))} */}
    </div>
  );
}

export default SectionRegals;
