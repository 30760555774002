import React, { Component } from 'react';
import CERTS from '../../../../models/certs';
const MAX_CERTS_PER_LINE = 5;

class SectionCertifications extends Component {
  state = {};
  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  render() {
    return (
      <React.Fragment>
        <h3 className='pb-3 pt-4 pt-sm-5'>Certifications</h3>
        {this.state.certsPerRow ? (
          <div
            id='xp-carousel'
            className='carousel slide'
            data-bs-ride='carousel'>
            <div className='carousel-inner'>
              {[...Array(this.getCertsPagesCount()).keys()].map((_, i) => (
                <div
                  className={i === 0 ? 'carousel-item active' : 'carousel-item'}
                  key={i}>
                  <div className='row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-xxl-5 gy-3 fade-in mb-2'>
                    {this.getCertsForPage(i).map((cert, index) => (
                      <div className='col' key={index}>
                        {cert && Object.keys(cert).length > 0 ? (
                          <figure className='cert-figure'>
                            <img
                              className='cert'
                              src={`/img/certs/${cert.image}`}
                              alt={cert.title}
                            />
                          </figure>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <button
              className='carousel-control-prev'
              type='button'
              data-bs-target='#xp-carousel'
              data-bs-slide='prev'>
              <span
                className='carousel-control-prev-icon'
                aria-hidden='true'></span>
              <span className='visually-hidden'>Previous</span>
            </button>
            <button
              className='carousel-control-next'
              type='button'
              data-bs-target='#xp-carousel'
              data-bs-slide='next'>
              <span
                className='carousel-control-next-icon'
                aria-hidden='true'></span>
              <span className='visually-hidden'>Next</span>
            </button>
          </div>
        ) : null}
      </React.Fragment>
    );
  }

  resize() {
    let width = document.querySelector('div.container').offsetWidth;
    let certsPerRow = 2;

    //md
    if (width >= 576 && width < 768) certsPerRow = 3;
    //lg
    else if (width >= 768 && width < 992) certsPerRow = 4;
    //xl or more
    else if (width >= 992) certsPerRow = 5;

    this.setState({ certsPerRow: certsPerRow });
  }

  getMaxCertsLines = () => Math.ceil(CERTS.length / MAX_CERTS_PER_LINE);
  getCertsPagesCount = () =>
    Math.ceil(
      CERTS.length / (this.state.certsPerRow * this.getMaxCertsLines())
    );
  getCertsForPage = (pageIndex) => {
    const certsPerRow = this.state.certsPerRow;
    const maxLines = this.getMaxCertsLines();
    let res = [];

    for (let line = 0; line < maxLines; line++) {
      for (let col = 0; col < certsPerRow; col++) {
        let index = pageIndex * certsPerRow + MAX_CERTS_PER_LINE * line + col;
        // console.log(index);

        if (index < CERTS.length && index < MAX_CERTS_PER_LINE * (line + 1)) {
          // console.log(CERTS[index].title);
          res.push(CERTS[index]);
        } else res.push({});
      }
    }
    // console.log(res);
    return res;
  };
}

export default SectionCertifications;
