import React, { Component } from 'react';
import './Home.css';
import SectionAbout from './SectionAbout/SectionAbout';
import SectionDrNova from './SectionDrNova/SectionDrNova';
import SectionProducts from './SectionProducts/SectionProducts';
import ScrollToAnchor from '../../components/Tools/ScrollToAnchor';
import SectionTestimonials from './SectionTestimonials/SectionTestimonials';

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <ScrollToAnchor />
        <div className='container-fluid p-0 red pt-5' id='drNova'>
          <div className='container'>
            <SectionDrNova></SectionDrNova>
          </div>
        </div>

        <div className='container-fluid p-0 py-5 blue' id='about'>
          <div className='container'>
            <SectionAbout></SectionAbout>
          </div>
        </div>

        <div className='container-fluid p-0 py-5 orange' id='services'>
          <div className='container'>
            <SectionProducts />
          </div>
        </div>

        <div className='container-fluid p-0 py-5 azure' id='testimonials'>
          <div className='container'>
            <SectionTestimonials />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
