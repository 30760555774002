import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      drNova: {
        title: 'Dr. Nova',
        slogan: 'Smart beauty & health',
        description: `Passionate and dedicated beauty professional with more than 5
                years of experience in the industry. My mission is to empower
                you to feel confident, beautiful, and uniquely you through
                personalized beauty services tailored to your needs.`,
        bottom: `I am ready to share the secrets of youth with you. Here you will
                learn a lot of exciting things about how to preserve your
                attractiveness for many years! Join me, and together we will
                make this world even more beautiful!`,
      },
      blog: {
        title: 'Beauty blog',
      },
    },
  },
  ru: {
    translation: {
      'Welcome to React': 'Bienvenue à React et react-i18next',
    },
    blog: {
      title: 'Блог',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
});

export default i18n;
