import React from 'react';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Header from './components/Header/Header';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Courses from './pages/Courses/Courses';
import Products from './pages/Products/Products';
import Contacts from './pages/Contacts/Contacts';
import Footer from './components/Footer/Footer';
import FAQ from './pages/FAQ/FAQ';
import Blog from './pages/Blog/Blog';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import BlogArticle from './pages/BlogArticle/BlogArticle';

const client = new ApolloClient({
  uri: 'https://api.dr-nova.com/graphql',
  cache: new InMemoryCache(),
});

function App() {
  return (
    <React.Fragment>
      <Header></Header>
      <ApolloProvider client={client}>
        <Routes>
          <Route path='index.html' element={<Navigate to='/' />} />
          <Route exact path='/' element={<Home />} />
          <Route exact path='/courses' element={<Courses />} />
          {/* <Route exact path='/products' element={<Products />} /> */}
          <Route path='/contacts' element={<Contacts />} />
          <Route path='/faq' element={<FAQ />} />
          {/* <Route path='/blog' element={<Blog />} />
          <Route path='/blog/:id' element={<BlogArticle />} /> */}
          <Route path='/sitemap.xml' />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </ApolloProvider>
      <Footer></Footer>
    </React.Fragment>
  );
}

export default App;
