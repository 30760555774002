const REGALS = [
  {
    title: 'Doctor with honored diploma',
    icon: { url: 'ico-doctor.png' },
  },
  {
    title: 'Cosmetologist-Aesthetician, 4th degree',
    icon: { url: 'ico-grad.png' },
  },
  {
    title: 'Beauty specialist 3 level [ITEC certificated]',
    icon: { url: 'ico-itec.png' },
  },
  {
    title: 'Endosphere therapy specialist',
    icon: { url: 'ico-endosphere.png' },
  },
  {
    title: 'Bioretherapy professional [chemical peeling BioRePeelCl3]',
    icon: { url: 'ico-peeling.png' },
  },
  {
    title: 'Mesotherapist [face & body + lipolytics]',
    icon: { url: 'ico-mesotherapy.png' },
    cssClass: 'left-8',
  },
  {
    title: 'Ultrasonic facial cleansing specialist',
    icon: { url: 'ico-ultrasound.png' },
    cssClass: 'left2',
  },
  {
    title: 'Professional piercer',
    icon: { url: 'ico-piercing.png' },
    cssClass: 'left2',
  },
];
export default REGALS;
