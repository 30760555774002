import React from 'react';
import XP_AND_EDUCATIONS from '../../../../models/xp-and-educations';
import useFetch from '../../../../hooks/useFetch';

const getXpClasses = (xp) =>
  `bi bi-xp ${xp.isDegree ? 'bi-mortarboard-fill' : 'bi-record-fill'} `;

function SectionExperience() {
  const { loading, error, data } = useFetch('');

  return (
    <React.Fragment>
      <h3 className='pb-3'>Experience and education</h3>
      <div id='ed-list'>
        {XP_AND_EDUCATIONS.map((xp, index) => (
          <div className='row pb-2 pb-sm-0' key={index}>
            <div className='col'>
              <div className='left'>
                <div className='left col-year text-center ms-md-4'>
                  {xp.year ? xp.year : '\u00A0'}
                </div>
                <div className='left col-vr d-flex mx-md-5 d-none d-md-block d-lg-block'>
                  <div className='vr'>
                    <i className={getXpClasses(xp)}></i>
                  </div>
                </div>
                <div className='left col-place'>
                  <div className='place'>{xp.place}</div>
                  <div>{xp.title}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}

export default SectionExperience;
