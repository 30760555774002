import React from 'react';
import './SectionTestimonials.css';
import useFetch from '../../../hooks/useFetch';
import TestimonialsCarousel from './sections/TestimonialsCarousel';

function SectionTestimonials() {
  const { loading, error, data } = useFetch('');

  return (
    <React.Fragment>
      <div className='white'>
        <h2 className='pb-3'>Dr. Nova's testimonials</h2>
        <p className='text-justify'>
          As beauty professional I believe that the best way to showcase the
          quality of our services is through the words of those who matter most
          - our clients. Testimonials are not just reviews; they are a
          reflection of the trust, satisfaction, and positive experiences our
          clients have with us. I take pride in the trust our clients have
          placed in us and invite you to explore their testimonials to discover
          why my services, products and courses are the top choice.
        </p>
        <p className='text-justify pb-3'>
          Let our happy clients guide you in making the right choice!
        </p>
      </div>

      <TestimonialsCarousel />
    </React.Fragment>
  );
}

export default SectionTestimonials;
